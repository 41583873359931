<template>
  <div class="form">
    <div class="form-title">
      <span>{{$t("form_Q4")}}</span>
      <span class="danger-text">{{$t("form_require")}}</span>
      <span class="ml-4 description-many">{{$t("form_many")}}</span>
    </div>

    <section style="margin: 0 15%">
      <div
        v-for="(item, index) in options"
        :key="index"
      >
        <v-checkbox
          v-model="checkList"
          :label="item"
          color="#132d7a"
          :value="item"
          @change="setData()"
        ></v-checkbox>
      </div>
    </section>
  </div>
</template>

<script>
import { options4 } from "./answer"

export default {
  name: 'Form4',
  data() {
    return {
      checkList: [],
      options: options4
    }
  },
  mounted() {
    let sessionItem = JSON.parse(sessionStorage.getItem('q4'))

    if(sessionItem) {
      this.checkList = sessionItem
    }
  },
  methods: {
    setData(){
      if(this.checkList.length) {
        sessionStorage.setItem('q4', JSON.stringify(this.checkList))
      } else {
        sessionStorage.setItem('q4', JSON.stringify(''))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;

  .form-title {
    font-size: max(16px, 1.8vw);
    font-weight: bold;
    color: #132d7a;
    text-align: center;
    margin-bottom: 3vw;
  }

  .v-input--checkbox {
    transform: scale(1.2);
  }
}

</style>